<template>
  <div
    id="crm-management"
    v-cloak
    v-loading.fullscreen.lock="loading"
    style="overflow-y: scroll"
  >
    <div class="container flex">
      <div class="grid-content flex full-w" id="null">
        <div class="flex flex-d-c">
          <h1 class="tit-tag-down tit-tag">
            <i class="iconfont icon-zuzhi"></i>
            {{ $t("management.waitingForAssigning") }}
          </h1>
          <span class="user-num mt60">
            {{ $t("management.number") }}：
            <em class="num" v-if="stayList">{{ stayList.num_total }}</em>
          </span>
        </div>
        <!-- <div class="user-box-out"> -->
        <template v-if="stayList">
          <draggable
            class="user-box assign-box"
            :list="stayList.list"
            group="user"
            v-bind="dragOptions"
            handle=".move"
            @end="assignUser"
          >
            <span
              v-for="item in stayList.list"
              :key="item.id"
              :class="[
                'user-info',
                { move: rightShow('CrmOther@userInfoPut') },
              ]"
              :data-id="item.id"
            >
              <img src="@/assets/default_photo.png" class="user-photo" />
              <span class="user-name ellipsis">{{ item.name }}</span>
            </span>
          </draggable>
        </template>
        <!-- </div> -->
      </div>
      <template v-for="(item, index) in crmList">
        <div
          class="grid-content half-w list-item_box"
          :id="item.id"
          :key="index"
        >
          <div class="flex-between">
            <div class="flex-a-center mt16">
              <!-- 部门名称 -->
              <h2 class="tit-tag-right tit-tag">
                <i class="iconfont icon-zuzhi mr5"></i>
                <span
                  v-show="!showEditInput[index]"
                  @dblclick="clickEditFn(index)"
                >
                  {{ item.class_name }}
                </span>
                <el-input
                  v-model="item.class_name"
                  v-show="showEditInput[index]"
                  size="mini"
                  class="edit-input"
                  :ref="'editInput' + index"
                  maxlength="30"
                  show-word-limit
                  @blur="hideEdit(index)"
                ></el-input>
              </h2>
              <!-- 部门人数 -->
              <span class="user-num ml50">
                {{ $t("management.number") }}：
                <span v-if="item.num_total != null" class="num">{{
                  item.num_total
                }}</span>
                <span v-else class="num">0</span>
              </span>
            </div>
            <div class="handel-btn_box mt16">
              <i
                class="iconfont icon-qingkong hand"
                v-if="rightShow('CrmOther@delCrm')"
                @click="delCrm(item.id)"
              ></i>
              <i
                class="el-icon-circle-plus-outline hand"
                v-if="rightShow('CrmOther@userInfoPut')"
                @click="addUser(item.id)"
              ></i>
            </div>
          </div>
          <div class="draggable-box">
            <draggable
              class="user-box"
              :list="item.userList"
              group="user"
              handle=".move"
              v-bind="dragOptions"
              @end="changeUser"
            >
              <!-- 人员信息 -->
              <span
                v-for="(userItem, i) in item.userList"
                :data-id="userItem.id"
                :data-class_id="userItem.class_id"
                :class="[
                  'user-info',
                  { move: rightShow('CrmOther@userInfoPut') },
                ]"
                :key="userItem.id"
              >
                <img
                  v-if="userItem.photo !== undefined"
                  src="@/assets/default_photo.png"
                  class="user-photo"
                />
                <span class="user-name ellipsis">{{ userItem.name }}</span>
                <i
                  class="el-icon-close hand"
                  v-if="
                    userItem.photo !== undefined &&
                    rightShow('CrmOther@delUser')
                  "
                  @click="delUser(index, i)"
                ></i>
              </span>
            </draggable>
          </div>
        </div>
      </template>
      <div
        class="grid-content half-w add-box list-item_box"
        v-if="rightShow('CrmOther@crmInfoPut')"
      >
        <div class="flex-a-center mt16">
          <h2 class="tit-tag-right tit-tag tit-tag-gray">
            <i class="iconfont icon-zuzhi mr5"></i>
            {{ $t("management.departmentName") }}
          </h2>
          <span class="user-num ml50">
            {{ $t("management.number") }}：
            <em class="num">{{ $t("management.number") }}：0</em>
          </span>
        </div>
        <div
          class="flex-d-c center flex add-btn_box hand"
          v-if="rightShow('CrmOther@crmInfoPut')"
          @click="addCrm"
        >
          <i class="el-icon-circle-plus-outline"></i>
          <span>{{ $t("management.addNewDepartment") }}</span>
        </div>
      </div>
      <el-dialog
        :title="dialogTit"
        :visible.sync="visible"
        width="400px"
        v-move
        class="group-dialog"
        center
      >
        <div class="flex-a-center flex-j-center">
          <span class="dia-span">
            {{ $t("management.departmentName") }}：
          </span>
          <el-input
            v-model.trim="className"
            size="mini"
            class="inputW"
            maxlength="30"
            show-word-limit
          ></el-input>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="visible = false" size="mini">
            {{ $t("cancel") }}
          </el-button>
          <el-button type="primary" @click="submit" size="mini">
            {{ $t("submit") }}
          </el-button>
        </div>
      </el-dialog>
      <el-dialog
        :title="dialogTit"
        :visible.sync="visibleUser"
        width="840px"
        v-move
        center
      >
        <div class="dia-con">
          <template v-if="stayList && stayList.list.length !== 0">
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAllUser"
              @change="handleCheckAllUser"
              >全选</el-checkbox
            >
            <div style="margin: 15px 0"></div>
            <el-checkbox-group
              v-model.trim="userId"
              @change="handleCheckedUser"
            >
              <el-checkbox
                v-for="sItem in stayList.list"
                :label="sItem.id"
                :key="sItem.id"
                class="mb10"
                >{{ sItem.name }}</el-checkbox
              >
            </el-checkbox-group>
          </template>
          <div v-else class="no-person">
            {{ $t("management.noPersonToBeAssigned") }}
          </div>
        </div>

        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="visibleUser = false" size="mini">
            {{ $t("cancel") }}
          </el-button>
          <el-button
            type="primary"
            @click="submitUser"
            size="mini"
            class="ml80"
            v-if="stayList && stayList.list.length !== 0"
          >
            {{ $t("submit") }}
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { getTabParam, setTabParam } from "@/utils/common";
import "@/plugins/move";
export default {
  components: {
    draggable,
  },
  name: "CRMManagement",
  data() {
    return {
      loading: true,
      stayList: null,
      status: "",
      crmList: [],
      visible: false,
      visibleUser: false,
      className: "",
      classInfo: "",
      dialogTit: "",
      id: "",
      userId: [],
      classId: "",
      showEditInput: [false, false, false], // 双击编辑
      checkAllUser: false,
      isIndeterminate: false,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: this.rightShow("CrmOther@userInfoPut") ? false : true,
        ghostClass: "ghost",
      };
    },
  },
  inject: ["rightShow"],
  methods: {
    // 获取列表
    getList: function () {
      this.loading = true;
      this.axios.post("/home/crmOther/getCrmList").then((res) => {
        this.loading = false;
        if (res.data.status !== 1) {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
          return;
        }
        this.stayList = res.data.data.stayList;
        this.crmList = res.data.data.crmList;
        res.data.data.crmList.forEach(() => {
          this.showEditInput.push(false);
        });
      });
    },
    //新增用户
    addUser(classId) {
      this.dialogTit = this.$t("management.addUsers");
      this.visibleUser = true;
      (this.userId = []), (this.classId = classId);
      this.checkAllUser = false;
    },
    //新增组织
    addCrm() {
      this.className = "";
      this.classInfo = "";
      this.dialogTit = this.$t("management.newDepartment");
      this.visible = true;
      this.id = null;
    },
    //编辑组织
    editCrm(id, className, classInfo) {
      this.dialogTit = this.$t("management.editDepartment");
      this.className = className;
      this.classInfo = classInfo;
      this.id = id;
      this.visible = true;
    },
    // 删除组织
    delCrm: function (id) {
      this.$confirm(this.$t("management.confirmDelete"), this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          this.axios
            .post("/home/crmOther/delCrm", {
              id: id,
            })
            .then((res) => {
              this.loading = false;
              if (res.data.status !== 1) {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
                return;
              }
              this.$message({
                message: "success",
                type: "success",
              });
              this.getList();
            });
        })
        .catch(() => {});
    },
    showTab: function () {
      let tabData = getTabParam();
      if (tabData.isTab) {
        this.getList();
      }
      tabData.isTab = false;
      setTabParam(tabData);
    },
    submit: function () {
      if (this.className == "") {
        this.$message({
          message: this.$t("management.pleaseFillDepartmentName"),
          type: "error",
        });
        return;
      }
      this.loading = true;
      let data = {};
      data.class_name = this.className;
      data.class_info = this.classInfo.trim();
      if (this.id) {
        data.id = this.id;
      }
      this.axios
        .post("/home/crmOther/crmInfoPut", data)
        .then((res) => {
          this.loading = false;
          if (res.data.status !== 1) {
            if (res.status !== 1) {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
              return;
            }
            this.$message({
              message: "success",
              type: "success",
            });
            this.getList();
            this.visible = false;
          }
        })
        .catch(() => {});
    },
    handleCheckAllUser: function (val) {
      this.userId = [];
      if (val && this.stayList.list) {
        this.stayList.list.forEach((item) => {
          this.userId.push(item.id);
        });
      }
      this.isIndeterminate = false;
    },
    handleCheckedUser: function (value) {
      let checkedCount = value.length;
      this.checkAllUser = checkedCount === this.stayList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.stayList.length;
    },
    submitUser: function () {
      if (this.userId == []) {
        this.$message({
          message: this.$t("management.pleaseSelectUser"),
          type: "error",
        });
        return;
      }
      this.loading = true;
      let data = {};
      data.class_id = this.classId;
      if (this.userId) {
        data.ids = this.userId;
      }
      this.axios
        .post("/home/crmOther/userInfoPut", data)
        .then((res) => {
          this.loading = false;
          if (res.data.status !== 1) {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
            return;
          }
          this.$message({
            message: "success",
            type: "success",
          });
          this.getList();
          this.visibleUser = false;
        })
        .catch(() => {});
    },
    // 双击编辑
    clickEditFn(index) {
      if (this.rightShow("CrmOther@crmInfoPut")) {
        this.showEditInput.splice(index, 1, true);
        setTimeout(() => {
          this.$refs["editInput" + index][0].focus();
        }, 0);
      }
    },
    // 失焦提交信息
    hideEdit(index) {
      this.showEditInput.splice(index, 1, false);
      if (!this.crmList[index].id) {
        this.$message({
          message: this.$t("management.paramError"),
          type: "error",
        });
      }
      if (!this.crmList[index].class_name) {
        this.$message({
          message: this.$t("management.pleaseFillDepartmentName"),
          type: "error",
        });
      }
      let ajax = {
        id: this.crmList[index].id,
        class_name: this.crmList[index].class_name,
      };
      this.axios
        .post("/home/crmOther/crmInfoPut", ajax)
        .then((res) => {
          this.loading = false;
          if (res.data.status !== 1) {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
            return;
          }
          this.$message({
            message: "success",
            type: "success",
          });
        })
        .catch(() => {});
    },
    // 删除用户
    delUser(classIndex, userIndex) {
      this.$confirm(this.$t("management.confirmDelete"), this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.axios
          .post("/home/crmOther/delUser", {
            id: this.crmList[classIndex].userList[userIndex].id,
            class_id: this.crmList[classIndex].id,
          })
          .then((res) => {
            this.loading = false;
            if (res.data.status !== 1) {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
              return;
            }
            this.getList();
            this.$message({
              message: "success",
              type: "success",
            });
          })
          .catch(() => {});
      });
    },
    assignUser(e) {
      // 都是在待分配里面 就不发送请求
      if (e.from.offsetParent.id === e.to.offsetParent.id) {
        return;
      }
      let data = {};
      data.class_id = e.to.offsetParent.id;
      data.ids = [e.item.dataset.id];
      this.axios
        .post("/home/crmOther/userInfoPut", data)
        .then((res) => {
          this.loading = false;
          if (res.data.status !== 1) {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
            this.getList();
            return;
          }
          this.getList();
        })
        .catch(() => {});
    },
    // 拖拽用户
    changeUser(e) {
      // 拖拽待分配
      if (e.to.offsetParent.id == "null") {
        this.loading = true;
        this.axios
          .post("/home/crmOther/delUser", {
            id: e.item.dataset.id,
            class_id: e.item.dataset.class_id,
          })
          .then((res) => {
            this.loading = false;
            if (res.data.status !== 1) {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
              this.getList();
              return;
            }
            this.getList();
          })
          .catch(() => {});
        return;
      }
      // 过滤同一个部门的
      if (e.item.dataset.class_id == e.to.offsetParent.id) {
        return;
      }
      // 部门之间的拖拽
      this.loading = true;
      this.axios
        .post("/home/crmOther/userInfoPut", {
          ids: [e.item.dataset.id],
          class_id: e.to.offsetParent.id,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status !== 1) {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
            this.getList();
            return;
          }
          this.getList();
        })
        .catch(() => {});
    },
  },
  created: function () {
    this.getList();
  },
};
</script>

<style scoped>
@import url("//at.alicdn.com/t/font_1249734_5vu79tw7nmr.css");
#crm-management {
  padding: 28px !important;
}
.inputW {
  margin: 0 10px;
}

.dia-span {
  display: inline-block;
  width: 100px;
  text-align: right;
}

.container {
  width: 1655px;
  flex-wrap: wrap;
  margin: 0 auto;
}

.grid-content {
  border-radius: 4px;
  min-height: 126px;
  padding: 0 32px 47px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  position: relative;
}
.grid-content:nth-child(even) {
  margin-right: 16px;
}
.grid-content.half-w {
  padding-bottom: 0;
}
.tit-tag {
  color: #fff;
  font-size: 12px;
  position: relative;
  text-align: center;
}
.tit-tag i {
  font-size: 24px;
  margin-bottom: 5px;
}
.tit-tag-down {
  width: 69px;
  min-height: 69px;
  background: #e6a23c !important;
  padding-top: 17px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.tit-tag-down::after {
  content: " ";
  position: absolute;
  left: 0;
  bottom: -30px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 34.5px 0 34.5px;
  border-color: #e6a23c transparent transparent transparent !important;
}
.tit-tag-right {
  height: 36px;
  min-width: 90px;
  /* max-width: 100px; */
  padding-left: 11px;
  padding: 6px 11px;
  display: flex;
  align-items: center;
}
.tit-tag-right::after {
  content: " ";
  position: absolute;
  right: -16px;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 18px 0 18px 16px;
  border-color: transparent transparent transparent #00adb5;
}
.grid-content .tit-tag {
  background: #00adb5;
}
.grid-content:nth-child(3n + 1) .tit-tag {
  background: #1c1c1e;
}
.grid-content:nth-child(3n + 2) .tit-tag {
  background: #409eff;
}
.grid-content:nth-child(3n + 3) .tit-tag {
  background: #f56c6c;
}
.tit-tag-gray {
  background: #888888 !important;
}
.grid-content:nth-child(3n + 1) .tit-tag::after {
  border-color: transparent transparent transparent #1c1c1e;
}
.grid-content:nth-child(3n + 2) .tit-tag::after {
  border-color: transparent transparent transparent #409eff;
}
.grid-content:nth-child(3n + 3) .tit-tag::after {
  border-color: transparent transparent transparent #f56c6c;
}

.tit-tag-gray::after {
  border-color: transparent transparent transparent #888888 !important;
}
.flex-d-c {
  flex-direction: column;
}
.user-box {
  display: flex;
  flex-wrap: wrap;
  padding-left: 36px;
  padding-top: 37px;
  /* width: 100%; */
}
.user-num {
  font-size: 14px;
  color: #1c1c1e;
}
.user-num .num {
  color: #ff7315;
}
.mt60 {
  margin-top: 60px;
}
.full-w {
  width: 100%;
}
.half-w {
  width: 819px;
}
.user-name {
  display: inline-block;
  width: 116px;
  font-size: 14px;
  margin-bottom: 0 !important;
}
.ml50 {
  margin-left: 50px;
}
.mt20 {
  margin-top: 20px;
}
.mt16 {
  margin-top: 16px;
}
.add-box {
  height: 318px;
}
.add-btn_box {
  font-size: 14px;
  color: #4a4a4a;
  text-align: center;
}
.add-btn_box i {
  font-size: 32px;
  margin-bottom: 15px;
}
.mb24 {
  margin-bottom: 24px;
}
.handel-btn_box {
  display: flex;
}
.handel-btn_box i {
  font-size: 20px;
}
.handel-btn_box i:nth-child(1) {
  color: #e1b769;
  margin-right: 24px;
}
.handel-btn_box i:nth-child(2) {
  color: #f56c6c;
}
.group-dialog >>> .el-dialog__body {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.group-dialog >>> .el-dialog__header {
  text-align: center;
  font-weight: 600;
}
.edit-input >>> .el-input__inner {
  padding: 2px 5px;
}
.user-info {
  position: relative;
  margin-bottom: 24px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.user-info >>> .el-icon-close {
  display: none;
  font-size: 14px;
  position: absolute;
  top: 0;
  right: 0;
  color: #f56c6c;
}
.user-info:hover >>> .el-icon-close {
  display: inline-block;
}
.user-photo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 5px;
}
.move {
  cursor: move;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-item_box {
  padding-left: 0;
}
.fixLeft {
  position: absolute;
  left: 38px;
  top: 73px;
  margin-top: 0;
}
.draggable-box {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 47px;
}
.draggable-box .user-box {
  width: 100%;
}
.dia-con {
  padding: 0 15px;
}
.ml80 {
  margin-left: 80px !important;
}
.assign-box {
  width: 100%;
}
.no-person {
  color: #333;
  font-size: 14px;
  text-align: center;
  margin-top: 30px;
}
</style>